import React from 'react';
import portfolioData from '../../portfolioData';

export default class Portfolio extends React.Component {
  render() {
    return (


      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Check Out Some of My Works.</h1>
            {/* portfolio-wrapper */}
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {this.props.portfolioData.map((item, i) => {


                return <div className="columns portfolio-item">
                  <div className="item-wrap">
                    <a href={'#modal-'+i} title>
                      
                      <img alt="" src={item.imgurl}/>
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>{item.name}</h5>
                          <p>{item.subtitle}</p>
                        </div>
                      </div>
                      <div className="link-icon"><i className="icon-plus" /></div>
                    </a>
                  </div>
                </div>

              })}


            </div> {/* portfolio-wrapper end */}
          </div> {/* twelve columns end */}
          {/* Modal Popup
            --------------------------------------------------------------- */}
          {this.props.portfolioData.map((item, i) => {


            return <div id={'modal-'+i} className="popup-modal mfp-hide">
              <img className="scale-with-grid" src={item.imgurl} alt="" />
              <div className="description-box">
                <h4>{item.name}</h4>
                <p>{item.description}</p>
                <span className="categories"><i className="fa fa-tag" />{item.subtitle}</span>
              </div>
              <div className="link-box">
                <a href={item.url}>Details</a>
                <a className="popup-modal-dismiss">Close</a>
              </div>
            </div>
             })}
            {/* modal End */ }
            
        </div>
      </section>
    );
  }
}
