let workData = {
    "jobs": [
        {
            "employerName": "Strategic Education, Inc",
            "jobTitle": "Vice President, Emerging Technology",
            "jobDescription": "As VP of Emerging Technology, I have the pleasure of leading a small team of engineers responsible for taking early stage technologies and applying them to both business side of education as well as education delivery. I and my team developed more 6 products that are now widely used within both Strayer and Capella Universities. These products include the use of conversational artificial intelligence, data intregration and advanced analytics. For each product developed I took a customer centric approach participating in surveying, prototyping, and piloting the solutions with the respective end users.",
            "startDate": "January 2018",
            "endDate": "Present",
        },
        {
            "employerName": "Strayer University",
            "jobTitle": "Executive Director, Artificial Intelligence & Automation",
            "jobDescription": "Prior to the merger of Strayer and Capella Universities I was tasked with creating an NLP based solution that would allow students to seek support via conversation, without the immediate need of student facing staff.  Tackling this challenge as a way to enable self service/support, I utilized the DialogFlow NLU/NLP platform to train an agent on the common areas of support and integrated those questions into the back office student information, financial aid management, and other systems to provide a personalized experience. This was a hands-on-keyboard role, starting with idea, prototyping, student pilots, and finally into production.  The resulting product has seen a greater than 87% satisfaction rate amongst students surveyed, and handles more than 93% of all chat requests without esclation to a human agent.",
            "startDate": "May 2017",
            "endDate": "January 2018",
        },
        {
            "employerName": "Strayer University",
            "jobTitle": "Executive Director, Student Financial Systems",
            "jobDescription": "In this role, I both contributed individually to the development and support of integrations with the financial aid management system at Strayer University. This role saw the development of custom integrations with Department of Education Systems, PeopleSoft's Campus Solution system, as well as the creation of custom systems for tracking and analyzing the processing of student financial aid.  One of the most pleasurable aspects of this role was the development of the team I had the pleasure to lead. By mentoring these team members, I helped to transform these domain experts into developers and analysts, providing them with professional and career development opportunities.",
            "startDate": "June 2011",
            "endDate": "May 2017",
        },
        {
            "employerName": "Regent Education",
            "jobTitle": "Director of Product Implementation",
            "jobDescription": "In this management role I oversaw and participated in the implementation of a SaaS based solution for student financial aid.  Working directly with clients, direct reports and colleagues, I established the overall implementation plan, data conversion process, and customizations required to fully implement the Regent Diamond solution. In this position I established deep relationships with the customers, putting their needs first and marrying those needs with the functionality available. Wearing a jack of all trades hat I developed creative solutions to any gaps that could have prevented a full and successful implementation, at times requiring process re-engineering, and the development of custom BPM solutions.",
            "startDate": "June 2009",
            "endDate": "June 2011",
        },
        {
            "employerName": "Regent Education",
            "jobTitle": "Senior Implementation Engineer",
            "jobDescription": "This individual conrtibutor role focused on the client specific implementation of the SaaS based Regent Diamond software solution. Relationships, internal and external, were nurtured and developed to deliver the best experience for the client and company. Execution of the specific implementation plan, tracking project metrics, and creation of customr repoorting solutions were common duties. As a result of the relationships built, Regent saw clients requesting my participation in many efforts post go-live.",
            "startDate": "March 2004",
            "endDate": "June 2009",
        },
        {
            "employerName": "Regent Education",
            "jobTitle": "Solutions Manager",
            "jobDescription": "This role was another jack of all trades, both developing software, including a custom reporting solution for the Regent Diamond Product, as well as acting as configuration and release manager. In this role I implemented continuous deliver and deployment, inclusive of testing, allowing for rapid release of software to production branches and providing immediate feedback to the entire development team on testing metrics, specifically pass/fail of detailed scenarios. ",
            "startDate": "March 2001",
            "endDate": "March 2004",
        }
    ]
}

export default workData; 