import React from 'react';
import resumeData from '../../resumeData';
import contactData from '../../contactData';
import aboutData from '../../aboutData';
export default class About extends React.Component {
  render() {
    return (

      <section id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src="images/profilepic.jpg" alt="" />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>{aboutData.bio}
            </p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{resumeData.name}</span><br />
                  <span>{contactData.address.streetNum} {contactData.address.street}<br />
                    {contactData.address.city}, {contactData.address.state} {contactData.address.zipCode}
                  </span><br />
                  <span>{contactData.mobile}</span><br />
                  <span>{contactData.email}</span>
                </p>
              </div>
              {/* <div className="columns download">
                  <p>
                    <a href="#" className="button"><i className="fa fa-download" />Download Resume</a>
                  </p>
                </div>
 */}              </div>
          </div>
        </div>
      </section>
    );
  }
}