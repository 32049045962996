let testimonialsData = [
    {
        "name": "Scott McKee",
        "title": "Public Sector Strategic Account Leader, DoD & 4th Estate",
        "company": "Gitlab Inc.",
        "endorsement": "It is my pleasure to write this recommendation for Daniel McCarthy. Daniel worked under my supervision as Director of Product of Delivery at Regent Education. Daniel's responsibilities included managing customer implementations, integrations with student information systems, leading a team of techinical/financial aid professionals, participating in business requirements gathering, tecnical design and report writing.Daniel establishes lasting relationships with both internal and external clients. He builds on those relationships with forthright expectation setting and his knowledge of the products and technology involved.Daniel's work ethic is unquestionable and he will stop at nothing to make sure a client is as successful as possible.I highly recommend Daniel in any technical integration or data mining/reporting role.",
        "profileImg": "https://media-exp1.licdn.com/dms/image/C4E03AQH_feQoUSOYhw/profile-displayphoto-shrink_100_100/0?e=1594857600&v=beta&t=D3cMlqp_W5vzFGIjeuP8AZzncuWOlagjl-DmETVELuU"

    },
    {
        "name": "Anne-Mari Moschera",
        "title": "Talent Acquisition Partner",
        "company": "Northrup Grumman",
        "endorsement": "Daniel is one of the most passionate person with whom I've had to work During his tenure at Regent, he showed strong poise and dedication to make sure deliverables were met. The most impressive characteristic about Dan is the strong and lasting relationships he build with Regent's customers at all levels. His one-on-one approach, caring and dedicated demeanor drove clients to request his participation on many projects",
        "profileImg": "https://media-exp1.licdn.com/dms/image/C4D03AQHWnDnbU7EuOg/profile-displayphoto-shrink_100_100/0?e=1594857600&v=beta&t=uQRnyAyM1lSURyLPh-lLSZoCl-MacU-07xdD56T4p5Y"
    },
    {
        "name": "Michael Ratti",
        "title": "Enterprise Account Manager",
        "company": "Amazon Web Services",
        "endorsement": "During my time at Regent Education Dan was considered a can-do person whom could be called in to any situation and immediately add value to the analysis and final resolution. Dan maintained a global picture with the future in mind and meticulously maintained his professional relationships with the Regent client base.",
        "profileImg": "https://media-exp1.licdn.com/dms/image/C4E03AQFr6YltEN74vg/profile-displayphoto-shrink_100_100/0?e=1594857600&v=beta&t=5P6-75n7iB34zI_du7Oc9BcC-cozqXNZFW403BspFqs"
    },
    {
        "name": "Hans Zeigler",
        "title": "Senior Director",
        "company": "Springer Lawson",
        "endorsement": "I had the pleasure of working with Dan for five years at Regent Education. Dan had an unmatched knack for protecting the interests of the company while forming strong and lasting relationships with our customers. He was truly an asset in delivering products and services to our clients.",
        "profileImg": "https://www.linkedin.com/in/hanszeigler/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3B0bATCbewSoyvC2hf0yGI%2Fg%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_flagship3_profile_view_base-recommendation_details_profile"
    },
    {
        "name": "Suresh Reddy",
        "title": "VP Engineering",
        "company": "Wilco Source",
        "endorsement": "Dan is one of the most talented people with whom I have worked. Dan is a thorough professional, with a good blend of technology and customer/client facing skills. Dan's energy at work place is quite phenomenon. A very dynamic team member who can really improve the morale of the team and create a very joyful and lively environment.",
        "profileImg": "https://media-exp1.licdn.com/dms/image/C4E03AQHg1KAByNScfA/profile-displayphoto-shrink_100_100/0?e=1594857600&v=beta&t=q78RdMtH76bVu3Yka46U0psY8aeinfIvWMlziq0lBRg"
    }
]

export default testimonialsData; 