import React from 'react';
import skillsData from '../../skillsData';
import workData from '../../workData';
export default class Resume extends React.Component {
  render() {



    return (


      <section id="resume">

        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>

          {this.props.credentialsData.education.map((school, i) => {


            return <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  <h3>{school.institionName}</h3>
                  <p className="info">{school.credentialEarned}<span>•</span> <em className="date">{school.endDate}</em></p>
                  <p>
                    {school.description}
                  </p>
                </div>
              </div>
            </div>
          })}
        </div>

        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Certifications</span></h1>
          </div>
          <div className="nine columns main-col">
            {this.props.credentialsData.certifications.map((cert, i) => {


              return <div className="row item">
                <div className="three columns"><img src={cert.credentialBadgeURL}></img></div>
                <div className="nine columns">
                  <h3>{cert.issuer}</h3>
                  <p className="info">{cert.crendentialName}<span>•</span> <em className="date">{cert.expirationDate}</em></p>
                  <p>
                    {cert.description}
                  </p>
                </div>
              </div>

            })}
          </div>
        </div>

        <div className="row work">
          <div className="three columns header-col">
            <h1><span>Work</span></h1>
          </div>
          <div className="nine columns main-col">
          {this.props.workData.jobs.map((job, i) => {


            return <div className="row item">
            <div className="twelve columns">
              <h3>{job.employerName}</h3>
              <p className="info">{job.jobTitle} <span>•</span> <em className="date">{job.startDate} - {job.endDate}</em></p>
              <p>
                {job.jobDescription}
                </p>
            </div>
          </div>

          })}
         
            
         
          </div>
        </div>

        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>
          <div className="nine columns main-col">
            <p> {skillsData.overview}
            </p>
          </div>
          <div className="nine columns main-col">

            <ul className="skillsArea">



              {this.props.skillsData.skills.map((area, i) => {
                //console.log(area)
                let skills = area.skills.map((skill, i) => {
                  return <li key={skill.name}> <span className="bar-expand " style={{ width: skill.proficiency + "%" }}>{skill.name}</span></li>
                });

                return <div>
                  <h2><span>{area.name}</span></h2>
                  <div className="bars"><ul>{skills}</ul></div>

                </div>



              })}


            </ul>
          </div>
        </div>
      </section>
    );
  }
}