import React from 'react';
import testimonialsData from '../../testimonialsData';
export default class Testimonials extends React.Component {
  render() {
    return (

      <section id="testimonials">
        <div className="text-container">
          <div className="row">
            <div className="two columns header-col">
              <h1><span>Client Testimonials</span></h1>
            </div>
            <div className="ten columns flex-container">
              <div className="flexslider">
                <ul className="slides">
                  {this.props.testimonialsData.map((item, i) => {


                    return <li className="row">
                      
                        <blockquote>
                          <p>{item.endorsement}
                        </p>
                          <cite>{item.name}, {item.title} @ {item.company}</cite>
                        </blockquote>
                    </li>
                  })}
                </ul>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}