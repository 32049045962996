let porfolioData = [
    {
        "name": "Google Cloud Next",
        "subtitle": "Presentation",
        "date": "April 2019",
        "type": "video",
        "url": "https://www.youtube.com/watch?v=tGwhO08v0UY",
        "description": "Having the honor and privilege to demonstrate and discuss what we have done with GCP to deliver the conversational agent, Irving",
        "imgurl": "/images/gcp_talk.png"
    },
    {
        "name": "Irving a Virtual Assistant for Students",
        "subtitle": "Video of Virtual Assistant",
        "date": "April 2020",
        "type": "Video",
        "url": "https://www.youtube.com/watch?v=OlqqYYCuFP4&feature=youtu.be", 
        "description": "This video was created to inform SEI investors about the virtual assistant project that I and my team built.",
        "imgurl": "/images/sei-irving.png"
    },
    {
        "name": "Google Cloud Blog",
        "subtitle": "Case Study",
        "date": "April 2020",
        "type": "Case Study",
        "url": "https://edu.google.com/why-google/case-studies/strategic-education-virtual-assistant/?modal_active=none",
        "description": "This case study illustrates how SEI has used GCP to deliver the a very quality conversational experience.",
        "imgurl": "images/google_case_study1.png"
    }, 
    {
        "name": "Using machine learning to transform online education and improve student outcomes",
        "subtitle": "Case Study",
        "date": "2017",
        "type": "Case Study",
        "url": "https://edu.google.com/why-google/case-studies/strayer-university-gcp/?modal_active=none",
        "imgurl": "images/google_case_study2.png"
    }
]

export default porfolioData; 