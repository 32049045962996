let contactData = {
    "email": "daniel.mccarthy@linuxphile.org",
    "mobile": "717.261.6134",
    "address": {
        "streetNum": "9265",
        "street": "207th Street West",
        "city": "Lakville",
        "state": "Minnesota",
        "zipCode": "55044"
    }
}

export default contactData;