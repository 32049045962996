let skillsData = {
    "overview": "I take a pragmattic approach to be developing new skills. Utilizing the skills below, I evaluate problems, recognized and unrecognized and look through my swiss army knife of skillsets and determine if any may be applied to the problem at hand.  When I come across problems that require a new skill set I set about learning those new skills be collaborating with peers, independent learning, and general conversations with experts in those areas.",
    "skills": [

        
        {
            "name": "Programming Languages",
            "skills":
                [
                    { "name": "C#", "proficiency": 60 },
                    { "name": "Javascript", "proficiency": 75 },
                    { "name": "NodeJS", "proficiency": 75 },
                    { "name": "Python", "proficiency": 40 },
                    { "name": "Bash", "proficiency": 80 },
                ]
        },
        {
            "name": "Database/Storage",
            "skills": [
                { "name": "MySQL", "proficiency": 80 },
                { "name": "SQL Server", "proficiency": 80 },
                { "name": "PostgreSQL", "proficiency": 40 },
                { "name": "Oracle", "proficiency": 40 },
                { "name": "MongoDB", "proficiency": 30 },
                { "name": "Cloud Datastore", "proficiency": 55 },
                { "name": "BigQuery", "proficiency": 65 }

            ]
        },
        {
            "name": "Management",
            "skills": [
                { "name": "Vendor Management", "proficiency": 60 },
                { "name": "Team Building", "proficiency": 80 },
                { "name": "Mentoring", "proficiency": 80 },
                { "name": "Strategy", "proficiency": 65 },
            ]
        },
        {
            "name": "Operating Systems",
            "skills": [
                { "name": "Linux (Ubuntu/Debian, Redhat/Fedora", "proficiency": 80 },
                { "name": "Windows Server 20XX", "proficiency": 60 }
            ]
        },
        {
            "name": "Other",
            "skills": [
                { "name": "DialogFlow", "proficiency": 85 },
                { "name": "Cloud Computing", "proficiency": 60 },
                { "name": "NLP", "proficiency": 40 },
                { "name": "Data Analytics", "proficiency": 65 },
            ]
        }
    ]

};

export default skillsData;