let EducationCredentials = {
    "education": [
        {
            "insitutionName": "Strayer University",
            "beginDate": "2012",
            "endDate": "2017",
            "credentialEarned": "Bachelor of Science, Information Technology",
            "description": "At Strayer University I studied Information Technology with a focus on database technologies earning a place on the Dean's list and graduating with a cummulative GPA of 3.875."
        }
    ],
    "certifications": [
        {
            "crendentialName": "Google Cloud Certified Professional Architect",
            "issuer": "Google",
            "beginDate": "05/2020",
            "expirationDate": "05/2022",
            "credentialBadgeURL": "images/gcppa.png",
            "description": "A Google Cloud Certified - Professional Cloud Architect enables organizations to leverage Google Cloud technologies. Through an understanding of cloud architecture and Google technology, this individual can design, develop, and manage robust, secure, scalable, highly available, and dynamic solutions to drive business objectives."
        },
        {
            "crendentialName": "Google Cloud Certified Associate Engineer",
            "issuer": "Google",
            "beginDate": "05/2019",
            "expirationDate": "05/2021",
            "credentialBadgeURL": "images/gcpace.png",
            "description": "An Associate Cloud Engineer deploys applications, monitors operations, and manages enterprise solutions. This individual is able to use Google Cloud Console and the command-line interface to perform common platform-based tasks to maintain one or more deployed solutions that leverage Google-managed or self-managed services on Google Cloud."
        },
        {
            "crendentialName": "MTA Database Fundamentals",
            "issuer": "Microsoft",
            "beginDate": "11/2016",
            "expirationDate": "no expiration",
            "credentialBadgeURL": "images/mta-database-fundamentals-certified.png",
            "description": "Database Fundamentals certification have demonstrated introductory knowledge of and skills with databases, including relational databases, such as Microsoft SQL Server.  The MTA program provides an appropriate entry point to a future career in technology."
        }

    ]
}

export default EducationCredentials