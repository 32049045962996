import React from 'react';
import logo from './logo.svg';
import Header from './components/header/header';
import About from './components/about/about';
import Resume from './components/resume/resume';
import Portfolio from './components/portfolio/portfolio';
import Testimonials from './components/testimonials/testimonials';
import ContactUs from './components/contactus/contactus';
import Footer from './components/footer/footer';
import resumeData from './resumeData';
import contactData from "./contactData";
import skillsData from "./skillsData";
import credentialsData from "./credentialsData";
import workData from './workData';
import './App.css';
import porfolioData from './portfolioData';
import testimonialsData from './testimonialsData';



function App() {
  return (
    <div className="App">
        <Header resumeData={resumeData} contactData={contactData}  />
        <About resumeData={resumeData}/>
        <Resume skillsData={skillsData} credentialsData={credentialsData} workData={workData} />
        <Portfolio portfolioData={porfolioData} />
        <Testimonials testimonialsData={testimonialsData} />
        {/* <ContactUs resumeData={resumeData} /> */}
        <Footer />
      </div>
  );
}

export default App;
